import React from "react";
import './partner.css'
import { Helmet } from 'react-helmet-async';

export default function Partner() {
    return (
        <div>
            <Helmet>
                <title>Trabaja con Diego Carranza - Contrataciones Inversiones Dubai</title>
                <meta name="description" content="¿Buscas optimizar tu negocio al maximo y trabajar con uno de los más grandes del mercado? Diego Carranza te asesora como invertir en Dubai de forma correcta" />
                <link rel="canonical" href="https://inversionesdubai.com" />
                <meta name="robots" content="index, follow" />
                <link rel="preload" href="https://res.cloudinary.com/dm9pjru06/image/upload/v1725567799/logos_valpo7.webp" as="image" type="image" crossorigin="anonymous" />
                <link rel="preload" href="https://res.cloudinary.com/dm9pjru06/image/upload/v1725564279/Dise%C3%B1o_sin_t%C3%ADtulo_3_kampen.webp" as="image" type="image" crossorigin="anonymous" />
            </Helmet>
            <div className="cnt_welcome-partner">
                <div className="cnt_welcome-partner-text">
                    <h2 className="subtitle-welcome-partner">Qué pasaría si te asocias con Diego,</h2>
                    <h1 className="title-welcome-partner">Para generar mayores ingresos y escalar tu empresa.</h1>
                </div>
                <div className="cnt_complement-welcome-partner">
                    <p className="complement-welcome-text-partner">Inversiones Dubái busca conectar con empresas, socios, emprendedores y personas interesadas en globalizar sus ingresos e invertir en el mercado más grande del mundo.</p>
                    <img src="https://danlok.com/wp-content/uploads/2022/09/Media-Logos-Dan-Lok.jpg" alt='logos' className='logos-partner' />
                </div>
            </div>
            <div className="cnt_information-partner">
                <h2 className="information-partner-title">BRINDAMOS <i className="color">RESULTADOS REALES</i></h2>
                <p className="information-partner-text">En el dinámico mundo de las inversiones, contar con la guía y el respaldo de un experto es fundamental para asegurar decisiones financieras inteligentes y exitosas. Diego Carranza, reconocido asesor de inversiones en Dubai, se ha consolidado como un referente en la industria, ofreciendo estrategias personalizadas que maximizan el crecimiento de sus clientes y protegen su patrimonio.</p>
                <div className="cnt_first-background-image">
                </div>
                <div className="cnt_information-partner-background">
                    <div className="cnt_information-partner-background-card">
                        <h2 className="subtitle-information-partner-background">SEGURIDAD Y <i className="color">CONFIANZA</i></h2>
                        <p className="information-partner-text colorwt">La seguridad de su capital es nuestra prioridad. Con años de experiencia en el mercado financiero de Dubai, Diego Carranza utiliza su amplio conocimiento para identificar oportunidades sólidas y minimizar riesgos. Gracias a sus consejos, se han generado más de 25 millones de dólares en beneficios, ayudando a clientes en más de 25 países. Diego ha sido reconocido como "Emprendedor del Año" en Dubai, lo que subraya su compromiso con la excelencia y el éxito de sus clientes.</p>
                    </div>
                    <div className="cnt_information-partner-background-card">
                        <h2 className="subtitle-information-partner-background">PROFESION Y <i className="color">EXCELENCIA</i></h2>
                        <p className="information-partner-text colorwt">Diego Carranza no solo ofrece asesoría financiera; brinda una experiencia de alto nivel respaldada por un equipo de profesionales dedicados. Nos enorgullece ofrecer un servicio impecable que se adapta a las necesidades específicas de cada cliente, garantizando un enfoque personalizado y resultados excepcionales.</p>
                    </div>
                </div>
            </div>
            <div className="cnt_button-partner">
                <a href="https://wa.link/vzpy3i" rel="noreferrer" target="_blank" className="button-partner">ASOCIATE A DIEGO</a>
            </div>
        </div>
    )
}