import React from "react";
import './training.css'

import { Helmet } from 'react-helmet-async';
import et from '../../assets/images/entrepeneur.webp'

export default function Training() {
    return (
        <div>
            <Helmet>
                <title>Entrena con el mentor Diego Carranza - Inversiones Dubai</title>
                <meta name="description" content="¿Buscas un mentor para aprender a invertir? Diego Carranza brinda mentoria para que aprendas sobre el mercado real de Dubai, criptomonedas, impuestos, bienes raices, etc." />
                <link rel="canonical" href="https://inversionesdubai.com" />
                <meta name="robots" content="index, follow" />
            </Helmet>
            <div className="cnt_training-banner">
                <h1 className="training-banner-text"><i className="color">ENTRENAMIENTO EXCLUSIVO</i><br /> POR DIEGO CARRANZA</h1>
            </div>
            <div className="cnt_training-information">
                <h2 className="training-information-title">¿Como entrenar con Diego?</h2>
                <p className="training-information-text">Si deseas entrenar con Diego Carranza, un mentor reconocido por sus logros y su inquebrantable disciplina a lo largo de su carrera, esta es tu oportunidad. Con Diego, tendrás acceso a un conocimiento profundo y práctico sobre el ámbito de las inversiones, tanto a corto como a largo plazo. Aprenderás a identificar y comprender las necesidades del cliente, así como a analizar y explorar los diversos caminos que pueden llevar a una persona a alcanzar su objetivo de globalizar su negocio en Dubái.</p>
                <p className="training-information-text">Además de la asesoría personalizada que ofrece, Diego cuenta con una sólida base de estudiantes que, con el tiempo, han salido al mercado real para asesorar a profesionales y ayudarlos a alcanzar sus metas en los Emiratos Árabes Unidos. Este programa no solo te brinda la teoría y las estrategias necesarias, sino que también te prepara para enfrentar los desafíos del mercado global, equipándote con las herramientas necesarias para sobresalir en un entorno competitivo.</p>
                <div className="cnt_first-background-image">
                </div>
                <p className="training-information-text">Al entrenar con Diego Carranza, no solo estarás aprendiendo de uno de los mejores en el campo, sino que también te estarás uniendo a una red de profesionales comprometidos con el éxito. Juntos, trabajarás para convertir tus aspiraciones en logros concretos, posicionando tu negocio en uno de los mercados más dinámicos y prometedores del mundo. ¡Este es el primer paso hacia la expansión y el éxito global que has estado buscando!</p>
                <a href="https://wa.link/vzpy3i" rel="noreferrer" target="_blank" className='button-contact color'>
                    INSCRIBETE AQUÍ
                </a>
                <div className="man-of-the-year">
                    <h2>NOMINADO A EMPRENDEDOR DEL AÑO</h2>
                    <div className="cnt_moty">
                        <div className="just-moty">
                            <img src={et} alt="diego" className="moty" />
                        </div>
                        <div className="just-moty">
                            <p className="moty-text">Diego carranza <br /><i className="color">Emprendedor del año</i><br /></p>
                            <a href="https://www.instagram.com/reel/C0HhnI0tn3c/?igsh=ZjRoOXJhZDZ2c2lj" rel="noreferrer" className='button-contact color size'>
                                MIRALO AQUÍ
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}