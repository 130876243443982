import React from "react";
import { Link } from "react-router-dom";

import nf from '../../assets/images/nofound.webp';
import { Helmet } from 'react-helmet-async';

export default function Notfound() {
    return (
        <div>
            <Helmet>
                <title>Not Found - Inversiones Dubai</title>
                <meta name="description" content="Page not found. Please return to our homepage." />
                <link rel="canonical" href="https://inversionesdubai.com" />
            </Helmet>
            <Link to="/">
                <img src={nf} className="nfimage" alt="notfound" />
            </Link>
        </div>
    )
}