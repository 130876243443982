import React from "react";
import Slider from 'react-slick';
import './guests.css'
import { Helmet } from 'react-helmet-async';

export default function Guests() {
    const data = [
        {
            image: 'https://faces.wtf/placeholder.png',
            name: 'Carlos Master Muñoz',
            text: '"Un podcast lleno de mucha alegria, temas y cultura, encantado de haber sido parte"',
        },
        {
            image: 'https://faces.wtf/placeholder.png',
            name: 'Jonnathan Saborio',
            text: '"Un podcast lleno de mucha alegria, temas y cultura, encantado de haber sido parte"',
        },
        {
            image: 'https://faces.wtf/placeholder.png',
            name: 'Max Schneir',
            text: '"Un podcast lleno de mucha alegria, temas y cultura, encantado de haber sido parte"',
        },
        {
            image: 'https://faces.wtf/placeholder.png',
            name: 'Abdullah Al Atrash',
            text: '"Un podcast lleno de mucha alegria, temas y cultura, encantado de haber sido parte"',
        },
        {
            image: 'https://faces.wtf/placeholder.png',
            name: 'Andres Crypto',
            text: '"Un podcast lleno de mucha alegria, temas y cultura, encantado de haber sido parte"',
        },
        {
            image: 'https://faces.wtf/placeholder.png',
            name: 'Jesus Vargas',
            text: '"Un podcast lleno de mucha alegria, temas y cultura, encantado de haber sido parte"',
        },
    ];

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        adaptiveHeight: true
    };
    return (
        <div className="cnt_guests">
            <Helmet>
                <title>Invitados especiales en el Podcast - Inversiones Dubai</title>
                <meta name="description" content="Conoce los mejores inversionistas, millonarios, influencers y todos los famosos que han asistido al podcast de Inversiones Dubai" />
                <link rel="canonical" href="https://inversionesdubai.com" />
                <meta name="robots" content="index, follow" />
            </Helmet>
            <div className="cnt_guests">
                <h1 className="guests-title">INVITADOS ESPECIALES AL PODCAST DE <i className="color">DIEGO CARRANZA</i></h1>
                <p className="guests-text">¿Eres una persona con ideas valiosas y experiencias únicas que podrían enriquecer nuestro podcast? ¿Tienes conocimientos que podrían inspirar o guiar a nuestra audiencia? Si resides en la vibrante ciudad de Dubái y te apasiona compartir tu historia, conocimientos o perspectivas, te invitamos a unirte a nosotros como invitado especial en el podcast de Diego Carranza.</p>
                <p className="guests-text">Estamos buscando personas que no solo deseen aportar información relevante, sino que también quieran ser parte de una conversación significativa que puede impactar a miles. Este es tu espacio para destacar tu expertise, conectar con una comunidad global y contribuir al crecimiento personal y profesional de quienes nos escuchan.</p>
            </div>
            <div className="carousel-container2 background">
                <h1 className="guests-title">INVITADOS</h1>
                <Slider {...settings}>
                    {data.map((item, index) => (
                        <div key={index} className="card-guess-podcast">
                            <img src={item.image} alt={item.title} className="card-image-guess" />
                            <div className='cnt_card-text-guess'>
                                <p className="card-name-guests">{item.name}</p>
                                <p className="card-text-guests">{item.text}</p>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
            <p className="guests-text">Si te interesa formar parte de este emocionante proyecto y dejar tu huella en la comunidad, no dudes en contactarnos. Queremos escucharte, aprender de ti, y juntos crear contenido que resuene y perdure. ¡Esperamos contar contigo en nuestro próximo episodio!</p>
            <a href='https://www.youtube.com/@InversionesDubai' className='button-youtube'>VER CANAL DE YOUTUBE</a>
        </div>
    )
}