import React from 'react';
import { PopupButton } from 'react-calendly';

const CalendlyPopupButton = () => {
    return (
        <div>
            <button className='backgroundoff'>
                <PopupButton
                    className='button-contact'
                    url="https://calendly.com/diego-carranza" 
                    rootElement={document.getElementById('root')}
                    text="AGENDA AQUÍ TU REUNION"
                />
            </button>
        </div>
    );
};

export default CalendlyPopupButton;
